import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthHttpClient } from "../auth/auth-client.service";
import { EntityType } from "@libs/shares/models/models";
import { EntityVerificationDto, VerificationType } from "@libs/shares/dto/entityVerificationDto";
import { BehaviorSubject, firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CrmService {
  verificationInfo$ = new BehaviorSubject<EntityVerificationDto | undefined>(undefined);

  constructor(private authHttpClient: AuthHttpClient) {}

  sendUpgradeRequest(
    orgId: string,
    body: Partial<{
      organizationName: string | null;
      firstName: string | null;
      lastName: string | null;
      organizationUrl: string | null;
      comment: string | null;
      entityType: EntityType | null;
      verificationType: VerificationType | null;
      monthlyLimit: number | null;
      rps: number | null;
    }>
  ) {
    return this.authHttpClient.post(`${environment.apiBaseUrl}/crm/${orgId}`, body);
  }

  async getVerificationInfo(orgId: string, entityType?: EntityType | null) {
    return firstValueFrom(
      this.authHttpClient.get<EntityVerificationDto>(`${environment.apiBaseUrl}/crm/kyb/${orgId}?type=${entityType}`)
    ).then((info) => {
      this.verificationInfo$.next(info);
      return info;
    });
  }

  async initiateVerification(orgId: string, entityType: EntityType, verificationType: VerificationType) {
    return firstValueFrom(
      this.authHttpClient.post<EntityVerificationDto>(
        `${environment.apiBaseUrl}/crm/kyb/${orgId}/${entityType}/${verificationType}`,
        {}
      )
    ).then((info) => {
      this.verificationInfo$.next(info);
      return info;
    });
  }
}
